import React from 'react';
import {
  Box,
  Button,
  Circle,
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { HiFilter } from 'react-icons/hi';
import { OrderQueryStore } from './useOrderQueryStore';
import ReactDatePicker from 'react-datepicker';
import {
  ALL_ORDER_STATUSES,
  MerchantAccount,
  OrderSortBy,
} from '@tradeaze-packages/schemas';
import { DebounceSearchInput } from '../OrderSearchInput';
import { RiderFilterMenu } from '../../rider/RiderFilterMenu';
import { DeliveryVehicleFilterMenu } from '../../delivery/delivery-vehicle/DeliveryVehicleFilterMenu';
import { WorkTypeFilterMenu } from '../../order/work-type/WorkTypeFilterMenu';
import { OrderStatusTag } from '../OrderStatusTag';
import { sortByToLabel } from './sortByToLabel';
import { MerchantFilterMenu } from '../../merchant';
import { NAVBAR_BACKGROUND_COLOR } from '../../navbar';
import { DownloadAdminCsv, DownloadMerchantCsv } from '../../csv';

const DownloadFilterSection = ({
  isAdmin,
  orderQueryStore,
  merchantAccount,
}: {
  isAdmin: boolean;
  orderQueryStore: OrderQueryStore;
  merchantAccount?: MerchantAccount;
}) => {
  return (
    <Box mb={6}>
      {isAdmin ? (
        <DownloadAdminCsv
          orderQueryStore={orderQueryStore}
          size="sm"
          borderColor={'white'}
          borderWidth={0.5}
        />
      ) : null}
      {!isAdmin && merchantAccount ? (
        <DownloadMerchantCsv
          orderQueryStore={orderQueryStore}
          merchantId={merchantAccount?.merchantId}
          size="sm"
          borderColor={'white'}
          borderWidth={0.5}
        />
      ) : null}
    </Box>
  );
};

const OrderReferenceFilterSection = ({
  orderQueryStore,
}: {
  orderQueryStore: Pick<
    OrderQueryStore,
    'merchantOrderReference' | 'handleChangeOrderReference'
  >;
}) => {
  const { merchantOrderReference, handleChangeOrderReference } =
    orderQueryStore;

  return (
    <Box mb={6}>
      <Heading size={'sm'} mb={4} color={'white'}>
        Order Reference
      </Heading>
      <Box>
        <DebounceSearchInput
          defaultValue={merchantOrderReference}
          inputPlaceholder="Order Reference"
          handleSearchChange={handleChangeOrderReference}
          height={'33px'}
        />
      </Box>
    </Box>
  );
};

const DateFilterSection = ({
  orderQueryStore,
}: {
  orderQueryStore: Pick<
    OrderQueryStore,
    | 'handleChangeStartDate'
    | 'handleChangeEndDate'
    | 'handleClearDates'
    | 'startDate'
    | 'endDate'
  >;
}) => {
  const {
    handleChangeStartDate,
    handleChangeEndDate,
    handleClearDates,
    startDate,
    endDate,
  } = orderQueryStore;

  return (
    <Box mb={6}>
      <Heading size={'sm'} mb={4} color={'white'}>
        Dates
      </Heading>
      <Stack
        direction="row"
        sx={{
          '.react-datepicker__input-container': {
            input: {
              padding: '3px 10px',
              borderRadius: '5px',
              width: '100%',
            },
          },
        }}
      >
        <ReactDatePicker
          placeholderText="Start Date"
          selected={startDate ? new Date(startDate) : undefined}
          onChange={handleChangeStartDate}
          selectsStart
          startDate={startDate ? new Date(startDate) : undefined}
          endDate={endDate ? new Date(endDate) : undefined}
          dateFormat="dd/MM/yyyy"
        />
        <ReactDatePicker
          placeholderText="End Date"
          selected={endDate ? new Date(endDate) : undefined}
          onChange={handleChangeEndDate}
          selectsEnd
          startDate={startDate ? new Date(startDate) : undefined}
          endDate={endDate ? new Date(endDate) : undefined}
          minDate={startDate ? new Date(startDate) : undefined}
          dateFormat="dd/MM/yyyy"
        />
        <CloseButton onClick={handleClearDates} />
      </Stack>
    </Box>
  );
};

const SortFilterSection = ({
  orderQueryStore,
}: {
  orderQueryStore: Pick<OrderQueryStore, 'handleChangeSortBy' | 'sortBy'>;
}) => {
  const { handleChangeSortBy, sortBy } = orderQueryStore;

  return (
    <Box mb={6}>
      <Heading size={'sm'} mb={4} color={'white'}>
        Sort
      </Heading>
      <RadioGroup my={3} onChange={handleChangeSortBy} value={sortBy}>
        <Stack direction="column">
          {Object.keys(sortByToLabel).map((sortBy) => (
            <Radio value={sortBy}>
              <Text color={'white'}>
                {sortByToLabel[sortBy as OrderSortBy]}
              </Text>
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    </Box>
  );
};

const DeliveryTodayFilterSection = ({
  orderQueryStore,
}: {
  orderQueryStore: Pick<
    OrderQueryStore,
    'handleToggleDeliveryToday' | 'deliveryToday'
  >;
}) => {
  const { handleToggleDeliveryToday, deliveryToday } = orderQueryStore;

  return (
    <Box mb={6}>
      <HStack>
        <Switch
          isChecked={deliveryToday}
          onChange={handleToggleDeliveryToday}
        />
        <Text color={'white'}>Delivery Today</Text>
      </HStack>
    </Box>
  );
};

const OrderStatusFilterSection = ({
  orderQueryStore,
}: {
  orderQueryStore: Pick<
    OrderQueryStore,
    'handleStatusFilterChange' | 'orderStatuses'
  >;
}) => {
  const { handleStatusFilterChange, orderStatuses } = orderQueryStore;

  return (
    <Box mb={6}>
      <Heading size={'sm'} mb={4} color={'white'}>
        Order Status
      </Heading>
      <Flex mb={6} wrap="wrap">
        {ALL_ORDER_STATUSES.map((status) => (
          <Box
            key={status}
            cursor={'pointer'}
            onClick={() => handleStatusFilterChange(status)}
            mr={2}
            mb={2}
            shadow="md"
          >
            <OrderStatusTag
              inactive={!orderStatuses?.includes(status)}
              status={status}
            />
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

const MerchantFilterSection = ({
  orderQueryStore,
}: {
  orderQueryStore: Pick<
    OrderQueryStore,
    'handleToggleMerchant' | 'handleSelectAllMerchants' | 'merchantIds'
  >;
}) => {
  const { handleToggleMerchant, handleSelectAllMerchants, merchantIds } =
    orderQueryStore;

  return (
    <Box mb={6}>
      <Heading size={'sm'} mb={4} color={'white'}>
        Merchant
      </Heading>
      <MerchantFilterMenu
        handleToggleMerchant={handleToggleMerchant}
        handleSelectAllMerchants={handleSelectAllMerchants}
        selectedMerchantIds={merchantIds}
      />
    </Box>
  );
};

const RiderFilterSection = ({
  orderQueryStore,
}: {
  orderQueryStore: Pick<
    OrderQueryStore,
    'handleToggleRider' | 'handleSelectAllRiders' | 'riderIds'
  >;
}) => {
  const { handleToggleRider, handleSelectAllRiders, riderIds } =
    orderQueryStore;

  return (
    <Box mb={6}>
      <Heading size={'sm'} mb={4} color={'white'}>
        Rider
      </Heading>
      <RiderFilterMenu
        handleToggleRider={handleToggleRider}
        handleToggleSelectAllRiders={handleSelectAllRiders}
        selectedRiderIds={riderIds}
      />
    </Box>
  );
};

const DeliveryVehicleFilterSection = ({
  orderQueryStore,
}: {
  orderQueryStore: Pick<
    OrderQueryStore,
    | 'handleToggleDeliveryVehicle'
    | 'handleSelectAllDeliveryVehicles'
    | 'deliveryVehicles'
  >;
}) => {
  const {
    handleToggleDeliveryVehicle,
    handleSelectAllDeliveryVehicles,
    deliveryVehicles,
  } = orderQueryStore;

  return (
    <Box mb={6}>
      <Heading size={'sm'} mb={4} color={'white'}>
        Delivery Vehicle
      </Heading>
      <DeliveryVehicleFilterMenu
        handleSelectAllDeliveryVehicles={handleSelectAllDeliveryVehicles}
        handleToggleDeliveryVehicle={handleToggleDeliveryVehicle}
        selectedVehicleIds={deliveryVehicles}
      />
    </Box>
  );
};

const WorkTypeFilterSection = ({
  orderQueryStore,
}: {
  orderQueryStore: Pick<
    OrderQueryStore,
    'handleToggleWorkType' | 'handleSelectAllWorkTypes' | 'workTypes'
  >;
}) => {
  const { handleToggleWorkType, handleSelectAllWorkTypes, workTypes } =
    orderQueryStore;

  return (
    <Box mb={6}>
      <Heading size={'sm'} mb={4} color={'white'}>
        Work Type
      </Heading>
      <WorkTypeFilterMenu
        handleSelectAllWorkTypes={handleSelectAllWorkTypes}
        handleToggleWorkType={handleToggleWorkType}
        selectedWorkTypes={workTypes}
      />
    </Box>
  );
};

export const OrderQueryOptionsDrawer: React.FC<{
  orderQueryStore: OrderQueryStore;
  isAdmin: boolean;
  buttonProps: React.ComponentProps<typeof Button>;
  merchantAccount?: MerchantAccount;
}> = ({ orderQueryStore, isAdmin, buttonProps, merchantAccount }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const showRiderFilter = isAdmin;
  const showMerchantFilter = isAdmin;
  const showWorkTypeFilter = isAdmin;
  const numberOfFilters = orderQueryStore.getActiveFilters();

  return (
    <Box>
      <Button {...buttonProps} onClick={onOpen}>
        <HStack>
          <HiFilter size={16} />
          <Text>Filters</Text>
        </HStack>
        {numberOfFilters > 0 && (
          <Circle
            position={'absolute'}
            right={-2}
            top={-1.5}
            minH={5}
            minW={5}
            bg="red.600"
            fontSize={10}
            color="white"
          >
            {numberOfFilters}
          </Circle>
        )}
      </Button>
      <Drawer placement={'right'} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent bg={NAVBAR_BACKGROUND_COLOR}>
          <DrawerHeader display={'flex'} justifyContent="space-between">
            <Text color={'white'}>Filter Orders</Text>
            <HStack>
              <CloseButton onClick={onClose} />
            </HStack>
          </DrawerHeader>
          <DrawerBody>
            <DownloadFilterSection
              isAdmin={isAdmin}
              orderQueryStore={orderQueryStore}
              merchantAccount={merchantAccount}
            />
            <OrderReferenceFilterSection orderQueryStore={orderQueryStore} />
            <DateFilterSection orderQueryStore={orderQueryStore} />
            <SortFilterSection orderQueryStore={orderQueryStore} />
            <DeliveryTodayFilterSection orderQueryStore={orderQueryStore} />
            {showMerchantFilter && (
              <MerchantFilterSection orderQueryStore={orderQueryStore} />
            )}
            {showRiderFilter && (
              <RiderFilterSection orderQueryStore={orderQueryStore} />
            )}
            <DeliveryVehicleFilterSection orderQueryStore={orderQueryStore} />
            {showWorkTypeFilter && (
              <WorkTypeFilterSection orderQueryStore={orderQueryStore} />
            )}
            <OrderStatusFilterSection orderQueryStore={orderQueryStore} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};
