import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  WorkType,
  getWorkTypeConfig,
  WORK_TYPE_CONFIGS,
} from '@tradeaze-packages/schemas';
import { useMemo } from 'react';

export const WorkTypeFilterMenu: React.FC<{
  handleToggleWorkType: (value: WorkType) => void;
  handleSelectAllWorkTypes: () => void;
  selectedWorkTypes?: WorkType[];
  size?: 'sm' | 'md' | 'lg';
  width?: string;
}> = ({
  handleToggleWorkType,
  handleSelectAllWorkTypes,
  selectedWorkTypes,
  size = 'sm',
  width = 'auto',
}) => {
  const title = useMemo(() => {
    if (!selectedWorkTypes?.length) {
      return 'Work Types';
    }
    if (selectedWorkTypes?.length === 1) {
      return getWorkTypeConfig(selectedWorkTypes[0])?.label;
    }
    return `${selectedWorkTypes.length} Work Types`;
  }, [selectedWorkTypes]);

  return (
    <Menu closeOnSelect={false}>
      <Box>
        <MenuButton
          colorScheme={'gray'}
          size={size}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          color={selectedWorkTypes?.length ? 'black' : 'gray.500'}
          textAlign={'left'}
          width={width}
        >
          {title}
        </MenuButton>
      </Box>
      <MenuList maxWidth={'max-content'}>
        <MenuItemOption
          isChecked={!selectedWorkTypes?.length}
          onClick={() => handleSelectAllWorkTypes()}
          fontWeight="normal"
        >
          All
        </MenuItemOption>
        {WORK_TYPE_CONFIGS.map(({ id, label }) => (
          <MenuItemOption
            key={id}
            isChecked={selectedWorkTypes?.includes(id)}
            onClick={() => handleToggleWorkType(id)}
          >
            {label}
          </MenuItemOption>
        ))}
      </MenuList>
    </Menu>
  );
};
