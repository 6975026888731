import {
  DeliveryVehicleId,
  OrderStatus,
  OrderSortBy,
  WorkType,
} from '@tradeaze-packages/schemas';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { formatDateIgnoreTime } from '@tradeaze/shared/utils';

export const DEFAULT_ORDER_QUERY_STATE = {
  startDate: undefined,
  endDate: undefined,
  merchantIds: undefined,
  orderStatuses: ['PENDING', 'CONFIRMED'] satisfies OrderStatus[],
  riderIds: undefined,
  sortBy: 'createdAt' as const,
  deliveryToday: false,
  merchantOrderReference: undefined,
  deliveryVehicles: undefined,
  workTypes: undefined,
  showCompleted: false,
  isUnassigned: false,
  isOverRunning: false,
  isOnJobBoard: false,
};

export type OrderQueryStore = {
  sortBy: OrderSortBy;
  endDate?: string;
  startDate?: string;
  deliveryToday?: boolean;
  merchantIds?: string[];
  orderStatuses: OrderStatus[];
  riderIds?: string[];
  merchantOrderReference?: string;
  deliveryVehicles?: DeliveryVehicleId[];
  workTypes?: WorkType[];
  showCompleted: boolean;
  isUnassigned: boolean;
  isOverRunning: boolean;
  isOnJobBoard: boolean;
  isDefaultFilters: () => boolean;
  getActiveFilters: () => number;
  handleStatusFilterChange: (status: OrderStatus) => void;
  handleToggleDeliveryToday: () => void;
  handleChangeSortBy: (sortBy: OrderSortBy) => void;
  handleChangeStartDate: (startDate: Date) => void;
  handleChangeEndDate: (endDate: Date) => void;
  handleClearDates: () => void;
  handleChangeOrderReference: (newOrderReference: string) => void;
  handleToggleRider: (riderId: string) => void;
  handleSelectAllRiders: () => void;
  handleToggleDeliveryVehicle: (deliveryVehicle: DeliveryVehicleId) => void;
  handleSelectAllDeliveryVehicles: () => void;
  handleToggleWorkType: (workType: WorkType) => void;
  handleSelectAllWorkTypes: () => void;
  handleToggleMerchant: (merchantId: string) => void;
  handleSelectAllMerchants: () => void;
  handleToggleShowCompleted: () => void;
  handleToggleUnassigned: () => void;
  handleToggleOverRunning: () => void;
  handleToggleOnJobBoard: () => void;
  handleResetFilters: () => void;
};

const checkIfArraysAreEqual = (arr1: unknown[], arr2: unknown[]) => {
  if (arr1.length !== arr2.length) return false;
  return (
    arr1.every((item) => arr2.includes(item)) &&
    arr2.every((item) => arr1.includes(item))
  );
};

const omit = <T extends object, K extends keyof T>(
  obj: T,
  keys: K[],
): Omit<T, K> => {
  const result = { ...obj };
  keys.forEach((key) => delete result[key]);
  return result;
};

export const useOrderQueryStore = create<OrderQueryStore>()(
  persist(
    (set, get) => ({
      ...DEFAULT_ORDER_QUERY_STATE,
      showCompleted: get()?.orderStatuses.includes('DELIVERED') ?? false,
      isDefaultFilters: () => {
        const currentState = get();
        return (
          Object.keys(DEFAULT_ORDER_QUERY_STATE) as Array<
            keyof typeof DEFAULT_ORDER_QUERY_STATE
          >
        ).every((key) => {
          const currentValue = currentState[key];
          const defaultValue = DEFAULT_ORDER_QUERY_STATE[key];

          if (Array.isArray(currentValue) && Array.isArray(defaultValue)) {
            return checkIfArraysAreEqual(currentValue, defaultValue);
          }

          return currentValue === defaultValue;
        });
      },
      getActiveFilters: () => {
        const {
          startDate,
          endDate,
          merchantIds,
          riderIds,
          merchantOrderReference,
          deliveryVehicles,
          workTypes,
          orderStatuses,
          deliveryToday,
        } = get();

        let count = 0;
        if (startDate) count++;
        if (endDate) count++;
        if (merchantIds?.length) count++;
        if (riderIds?.length) count++;
        if (merchantOrderReference) count++;
        if (deliveryVehicles?.length) count++;
        if (workTypes?.length) count++;
        if (orderStatuses.length !== 5 && orderStatuses.length !== 0) count++;
        if (deliveryToday) count++;
        return count;
      },
      handleStatusFilterChange: (status: OrderStatus) =>
        set(({ orderStatuses }) => {
          const newStatuses = orderStatuses?.includes(status)
            ? orderStatuses.filter((s) => s !== status)
            : [...orderStatuses, status];

          const showCompleted = newStatuses.includes('DELIVERED');

          return {
            showCompleted,
            orderStatuses: newStatuses,
          };
        }),
      handleChangeSortBy: (sortBy: OrderSortBy) => set({ sortBy }),
      handleToggleDeliveryToday: () =>
        set(({ deliveryToday }) => ({
          deliveryToday: !deliveryToday,
        })),
      handleChangeStartDate: (newDate) => {
        set({ startDate: formatDateIgnoreTime(newDate) });
      },
      handleChangeEndDate: (newDate) => {
        set({ endDate: formatDateIgnoreTime(newDate) });
      },
      handleChangeOrderReference: (newOrderReference) => {
        set({ merchantOrderReference: newOrderReference });
      },
      handleClearDates: () => {
        set({ startDate: undefined, endDate: undefined });
      },
      handleToggleRider: (riderId: string) => {
        set(({ riderIds }) => ({
          riderIds: riderIds?.includes(riderId)
            ? riderIds.filter((id) => id !== riderId)
            : [...(riderIds ?? []), riderId],
        }));
      },
      handleSelectAllRiders: () => {
        set({ riderIds: undefined });
      },
      handleSelectAllMerchants: () => {
        set({ merchantIds: undefined });
      },
      handleToggleMerchant: (merchantId: string) => {
        set(({ merchantIds }) => ({
          merchantIds: merchantIds?.includes(merchantId)
            ? merchantIds.filter((id) => id !== merchantId)
            : [...(merchantIds ?? []), merchantId],
        }));
      },
      handleToggleDeliveryVehicle: (deliveryVehicle: DeliveryVehicleId) => {
        set(({ deliveryVehicles }) => {
          return {
            deliveryVehicles: deliveryVehicles?.includes(deliveryVehicle)
              ? deliveryVehicles.filter((id) => id !== deliveryVehicle)
              : [...(deliveryVehicles ?? []), deliveryVehicle],
          };
        });
      },
      handleSelectAllDeliveryVehicles: () => {
        set({ deliveryVehicles: undefined });
      },
      handleToggleWorkType: (workType: WorkType) => {
        set(({ workTypes }) => ({
          workTypes: workTypes?.includes(workType)
            ? workTypes.filter((id) => id !== workType)
            : [...(workTypes ?? []), workType],
        }));
      },
      handleSelectAllWorkTypes: () => {
        set({ workTypes: undefined });
      },
      handleToggleShowCompleted: () =>
        set((state) => {
          const currentShowCompleted = state.showCompleted;

          const newShowCompleted = !currentShowCompleted;

          return {
            showCompleted: newShowCompleted,
            orderStatuses: newShowCompleted
              ? [...state.orderStatuses, 'DELIVERED']
              : state.orderStatuses.filter((status) => status !== 'DELIVERED'),
          };
        }),
      handleToggleUnassigned: () =>
        set((state) => {
          const defaults = omit(DEFAULT_ORDER_QUERY_STATE, [
            'deliveryToday',
            'sortBy',
          ]);

          return {
            ...defaults,
            isUnassigned: !state.isUnassigned,
            isOnJobBoard: false,
            isOverRunning: false,
          };
        }),
      handleToggleOnJobBoard: () =>
        set((state) => {
          const defaults = omit(DEFAULT_ORDER_QUERY_STATE, [
            'deliveryToday',
            'sortBy',
          ]);

          return {
            ...defaults,
            isUnassigned: false,
            isOnJobBoard: !state.isOnJobBoard,
            isOverRunning: false,
          };
        }),
      handleToggleOverRunning: () =>
        set((state) => {
          const defaults = omit(DEFAULT_ORDER_QUERY_STATE, [
            'deliveryToday',
            'sortBy',
          ]);

          return {
            ...defaults,
            isUnassigned: false,
            isOnJobBoard: false,
            isOverRunning: !state.isOverRunning,
          };
        }),
      handleResetFilters: () => set(() => ({ ...DEFAULT_ORDER_QUERY_STATE })),
    }),
    {
      name: 'order-query-store',
      getStorage: () => sessionStorage,
    },
  ),
);
