import { OrderStatus } from '../models';
import { WorkType, WorkTypeConfig } from '../models/work-type-schema';

export const ALL_ORDER_STATUSES: OrderStatus[] = [
  'PENDING',
  'CONFIRMED',
  'DELIVERED',
  'REJECTED',
  'CANCELLED',
];

export const WORK_TYPE_CONFIGS: WorkTypeConfig[] = [
  {
    id: 'GIG',
    label: 'Gig',
  },
  {
    id: 'SHIFT',
    label: 'Shift',
  },
  {
    id: 'EXTERNAL',
    label: 'External',
  },
  {
    id: 'UNASSIGNED',
    label: 'Unassigned',
  },
];

export const getWorkTypeConfig = (id: WorkType) => {
  const config = WORK_TYPE_CONFIGS.find((v) => v.id === id);
  if (!config) {
    throw new Error(`Work type config not found for workType: ${id}`);
  }
  return config;
};
