import { z } from 'zod';

export const WorkTypeSchema = z.enum([
  'GIG',
  'SHIFT',
  'EXTERNAL',
  'UNASSIGNED',
]);

export const WorkTypeConfigSchema = z.object({
  id: WorkTypeSchema,
  label: z.string(),
});

export type WorkType = z.infer<typeof WorkTypeSchema>;

export type WorkTypeConfig = z.infer<typeof WorkTypeConfigSchema>;
